






import Vue from 'vue'
import VueTradingView from 'vue-trading-view'
import { mapState } from 'vuex'
import helpers from '@/utils/helpers'

export default Vue.extend({
  name: 'TradingView',

  components: {
    VueTradingView
  },

  data(): { options: Record<string, any>; key: number } {
    return {
      options: {
        autosize: true,
        timezone: 'Etc/UTC',
        locale: 'en',
        interval: '1',
        toolbar_bg: '#f1f3f6',
        enable_publishing: false,
        allow_symbol_change: false,
        hide_side_toolbar: false,
        hide_top_bar: true,
        theme: 'dark',
        style: '1',
        width: '100%',
        height: '100%'
      },
      key: 1
    }
  },

  computed: {
    ...mapState('exchange', ['pair']),

    pairUpper(): string {
      if (!this.pair) return ''
      return helpers.getCorrectExchange(this.pair.exchange).toUpperCase()
    }
  },

  methods: {
    toRawSymbol(symbol: string): string {
      return symbol.replace(/[^a-zA-Z0-9]/g, '')
    }
  },

  created() {
    this.options = {
      ...this.options,
      symbol: this.pair ? `${this.pairUpper}:${this.toRawSymbol(this.pair?.symbol)}` : ''
    }
  },

  watch: {
    pair: {
      handler() {
        if (this.pair) {
          this.options = {
            ...this.options,
            symbol: `${this.pairUpper}:${this.toRawSymbol(this.pair?.symbol)}`
          }

          this.key = this.key + 1
        }
      },
      deep: true
    }
  }
})
